<template>
    <v-container>
        <v-col :cols= breakpoints class="mx-auto">
            <h1>Mein Steckbrief</h1>
            <v-col>
                <v-text-field
                label="Geburtstag"
                v-model="Geburtstag"
                outlined></v-text-field>
                <v-text-field
                label="Lieblingsessen"
                v-model="Lieblingsessen"
                outlined></v-text-field>
                <v-text-field
                label="Lieblingsfilm"
                v-model="Lieblingsfilm"
                outlined></v-text-field>
                <v-text-field
                label="Spitzname"
                v-model="Spitzname"
                outlined></v-text-field>
                <v-text-field
                label="Beitritt"
                v-model="Beitritt"
                outlined></v-text-field>
                <v-text-field
                label="Posten"
                v-model="Posten"
                outlined></v-text-field>
                <v-btn 
                color="primary" 
                x-large 
                class="col-12"
                @click="PostData()">Steckbrief Aktualisieren</v-btn>
            </v-col>
        </v-col>
    </v-container>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
export default {
    data:() => {
        return {
            Geburtstag: "",
            Lieblingsessen: "",
            Lieblingsfilm: "",
            Spitzname: "",
            Beitritt: "",
            Posten: "",
            UserId: localStorage.getItem('UID') || ""
        }
    },
    computed: {
        /* eslint-disable */ 
      breakpoints () { 
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 12
          case 'sm': return 12
          case 'md': return 6
          case 'lg': return 6
          case 'xl': return 6
        }
      },
    },
    methods: {
        fetchData: function () {
            axios
            .get('https://kjg-api.rezept-zettel.de/api/auth/' + this.UserId)
            .then(Response => {
                let Data = Response.data
                this.Geburtstag = Data.Steckbrief[0].Geburtstag
                this.Lieblingsessen = Data.Steckbrief[0].Lieblingsessen
                this.Lieblingsfilm = Data.Steckbrief[0].Lieblingsfilm
                this.Spitzname = Data.Steckbrief[0].Spitzname
                this.Beitritt = Data.Steckbrief[0].Beitritt
                this.Posten = Data.Steckbrief[0].Posten
            })
        },
        PostData: function () {
            axios
            .patch('https://kjg-api.rezept-zettel.de/api/auth/steckbrief/' + this.UserId, {
                Steckbrief: {
                Geburtstag: this.Geburtstag,
                Lieblingsessen: this.Lieblingsessen,
                Lieblingsfilm: this.Lieblingsfilm,
                Spitzname: this.Spitzname,
                Beitritt: this.Beitritt,
                Posten: this.Posten,
                }
            })
            .then(Response => {
                console.log(Response.data)
                Vue.$toast.open({
                  message: 'Steckbrief wurde erfolgreich bearbeitet !',
                  type: 'success',
              });
            })
            .catch((err) => {
                console.log(err)
                Vue.$toast.open({
                  message: 'Versuche es später erneut',
                  type: 'error',
              });
            })
        }
    },
    created() {
        this.fetchData()
    }
}
</script>

<style>

</style>